<script setup lang="ts">
import Icon from './Icon.vue';
/**
 * Tag component that can have only text, only an icon (with or without an action), or both
 * @displayName Tag
 */
export type Variant = 'primary' | 'secondary';
export type Usage = 'success' | 'error' | 'warning' | 'light' | 'uppercase' | 'default';
export type IconType = 'cancel' | 'check' | 'plus' | string;

interface Props {
  /**
   * Variant
   * @default primary
   * @values primary, secondary
   */
  variant?: Variant;
  /**
  * Usage
  * @default null
  * @values success, error, warning, light, uppercase, default
  */
  usage?: Usage;
  /**
   * Icon to display
   * @values cancel, check, plus, others...
   */
  icon?: IconType;
  /**
   * Event emitted when the icon is clicked
   * Specify if the tag should be in large format
   */
  large?: boolean;
  /**
   * keyword Icon to be displayed on the right
   */
  buttonIcon?: string;
}

interface Emits {
  /**
   * Function to be executed when the icon is clicked
   */
  (e: 'buttonClicked', event: Event): void;
}

const slots = useSlots();
const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(), {
  variant: 'primary',
  usage: 'default',
  icon: undefined,
  large: false,
  buttonIcon: undefined,
});
</script>
<template>
  <div :class="[`o-tag -${variant} -${usage}`, { '-large': large }]">
    <Icon
      v-if="icon"
      :name="icon"
      size="sm"
      :stroke-width="2"
    />
    <span
      v-if="slots.default"
      class="o-tag__label"
    >
      <!-- @slot Text content for the Tag -->
      <slot />
    </span>
    <button
      v-if="buttonIcon"
      type="button"
      class="o-tag__button"
      @click="emit('buttonClicked', $event)"
    >
      <Icon
        :name="buttonIcon"
      />
    </button>
  </div>
</template>
